import React, {FC, useEffect} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd,
  faEnvelope,
  faFileContract,
  faLocationPin, faPersonDigging,
  faPhone,
  faTrash,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime, useRefreshEffect} from "../components/RefreshController";
import {Customer, Sla, SlaRuleType, TicketPriority, User} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import {Button, IconButton} from "../components/form/Button";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {useModal} from "../components/layout/ModalProvider";
import {AddCustomerUserModal} from "../modals/AddCustomerUserModal";
import {Accordion} from "../components/data/Accordion";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {AddCustomerSLAModal} from "../modals/AddCustomerSLAModal";
import {DeleteCustomerSLAModal} from "../modals/DeleteCustomerSLAModal";
import {EditCustomerUserModal} from "../modals/EditCustomerUserModal";

export const CustomerDetails: FC = () => {
  const {getCustomer, getAllPriorities, getSlaForCustomer} = useApiCall()
  const {tenant} = useTenant()
  const {customer} = useParams()
  const {resource: customerDetails, reload: reloadCustomer, loading: loadingCustomer} = useFetchedResource((customer: string) => getCustomer(customer))
  const {resource: customerSla, loading: loadSla, reload: reloadSla} = useFetchedResource((customer: string) => getSlaForCustomer(customer))
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const latestRefreshTime = useLatestRefreshTime()

  useEffect(() => {
    if (customer === undefined) {
      console.warn('customer uuid is undefined')
      return
    }
    reloadCustomer(customer)
    reloadSla(customer)
    reloadPriorities(undefined)
  }, [customer, latestRefreshTime])

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader backButton={true} icon={faUser}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
          {label: "Klanten", href: `/${tenant}/customers`}
        ]} currentPage={customerDetails?.name ?? "Klant details"}/>
        <PageHeader>{customerDetails?.name}</PageHeader>
      </PageIconHeader>

        <div className={"flex flex-row space-x-8 mb-4"}>
          <div className={"flex flex-col basis-2/5 space-y-8"}>
            {customerDetails &&
              <CustomerInfo customer={customerDetails}/>
            }
          </div>

          <div className={"flex flex-row basis-3/5"}>
            {(customerSla && ticketPriorities && customerDetails) ?
              <CustomerSlaInfo sla={customerSla[0]} priorities={ticketPriorities} customer={customerDetails}/> :
              <>
                {customerDetails ?
                  <CustomerSlaInfo customer={customerDetails}/> : []
                }
              </>
            }
          </div>
        </div>

      {customerDetails && customer &&
        <CustomerUsers customer={customerDetails} users={customerDetails?.users ?? []}
                       reload={() => reloadCustomer(customer)}/>
      }

    </ContentContainer>
  )
}

const CustomerInfo: FC<{ customer: Customer }> = (props) => {
  return <div className={"rounded justify-center items-center dark:bg-zinc-700"}>
    <div className={"px-2 py-1 rounded border border-slate-200 dark:border-zinc-600 shadow space-y-3"}>
      <div className={"flex space-x-2 items-center py-2 px-1"}>
        <div className={"rounded-full text-brand-700 dark:text-brand-200 bg-brand-200 dark:bg-brand-700 flex items-center justify-center h-10 w-10"}>
          <FontAwesomeIcon className={""} icon={faUser}/>
        </div>
        <h1>{props.customer.contact_person}</h1>
      </div>

      {/* Customer address */}
      {props.customer.address &&
        <div className={"flex space-x-2 items-center py-2 px-1"}>
          <div className={"rounded-full text-brand-700 dark:text-brand-200 bg-brand-200 dark:bg-brand-700 flex items-center justify-center h-10 w-10"}>
            <FontAwesomeIcon className={""} icon={faLocationPin}/>
          </div>
          <h1>{props.customer.address}</h1>
        </div>
      }

      {/* Customer email */}
      <div className={"flex flex-wrap"}>
        {props.customer.email &&
          <div
            className={"flex items-center justify-center space-x-1 h-8 px-2 text-sm font-medium bg-transparent hover:bg-brand-100 hover:dark:bg-brand-600 text-brand-600 hover:text-brand-700 hover:dark:text-brand-200 rounded hover:cursor-pointer mr-2"}
            onClick={() => window.location.href = `mailto:${props.customer.email}`}>
            <FontAwesomeIcon icon={faEnvelope}/>
            <h1>{props.customer.email ? props.customer.email : "Niet bekend"}</h1>
          </div>
        }

        {/* Customer phone */}
        {props.customer.phone &&
          <div
            className={"flex items-center justify-center space-x-1 h-8 px-2 text-sm font-medium bg-transparent hover:bg-brand-100 hover:dark:bg-brand-600 text-brand-600 hover:text-brand-700 hover:dark:text-brand-200 rounded hover:cursor-pointer mr-2"}
            onClick={() => window.location.href = `tel:${props.customer.phone}`}>
            <FontAwesomeIcon icon={faPhone}/>
            <h1>{props.customer.phone ? props.customer.phone : "Niet bekend"}</h1>
          </div>
        }

        {/* Customer web url */}
        {props.customer.url &&
          <div
            className={"flex items-center justify-center space-x-1 h-8 px-2 text-sm font-medium bg-transparent hover:bg-brand-100 hover:dark:bg-brand-600 text-brand-600 hover:text-brand-700 hover:dark:text-brand-200 rounded hover:cursor-pointer mr-2"}
            onClick={() => window.location.href = `https://${props.customer.url}`}>
            <FontAwesomeIcon icon={faGlobe}/>
            <h1>{props.customer.url ? props.customer.url : "Niet bekend"}</h1>
          </div>
        }
      </div>
    </div>
  </div>
}

const CustomerSlaInfo: FC<{ sla?: Sla, priorities?: TicketPriority[], customer?: Customer }> = (props) => {
  const {getAllSlas} = useApiCall()
  const {resource: SlaItems, reload: reloadSla, loading: loadSla} = useFetchedResource(() => getAllSlas())
  const rulesMap: { [ruleType: string]: { [priorityId: string]: { [timeWindow: string]: string } } } = {}
  const headerStyle = 'font-medium text-sm text-left pr-8 py-2 whitespace-nowrap'
  const dataStyle = 'text-sm text-left pr-8 py-2 whitespace-nowrap'
  useRefreshEffect(() => {

    reloadSla(undefined)
  })

  const {open: openAddSLAModal} = useModal({title: 'SLA toevoegen', body: <AddCustomerSLAModal customer={props.customer!} slas={SlaItems!}/>, size: "md"})
  const {open: openDeleteSLAModal} = useModal({title: 'SLA verwijderen', body: <DeleteCustomerSLAModal customer={props.customer!} slas={SlaItems!}/>, size: "md"})

  {props.sla && props.priorities &&
    props.sla.rules.forEach((rule) => {
      if (!rulesMap[rule.rule_type]) {
        rulesMap[rule.rule_type] = {};
      }
      if (!rulesMap[rule.rule_type][rule.priority_id]) {
        rulesMap[rule.rule_type][rule.priority_id] = {};
      }
      const hoursKey = rule.during_office_hours ? 'inside_office_hours' : 'outside_office_hours';
      rulesMap[rule.rule_type][rule.priority_id][hoursKey] = rule.value;
    });
  }

  function slaNameRefactor(header: string): string {
    switch (header) {
      case SlaRuleType.maxResponse:
        return 'Maximale reactie tijd (in minuten)';
      case SlaRuleType.maxSolve:
        return 'Maximale oplos tijd (in minuten)';
      case SlaRuleType.uptime:
        return 'Uptime (in %)';
      case SlaRuleType.supportHours:
        return 'Aantal support uren';
      default:
        return 'Unknown SLA Rule';
    }
  }

  return <div className={"flex flex-col flex-1 border border-slate-200 dark:border-zinc-600 dark:bg-zinc-700 shadow rounded px-4 py-2 space-y-4"}>
    <div className={"flex flex-row justify-between"}>
      <div className={"flex space-x-2 items-center"}>
        <div className={"rounded-full text-brand-700 dark:text-brand-200 bg-brand-200 dark:bg-brand-700 flex items-center justify-center h-10 w-10"}>
          <FontAwesomeIcon className={""} icon={faFileContract}/>
        </div>
        <p>SLA</p>
      </div>

      {props.sla &&
        <div className={"flex space-x-2 items-center"}>
          <div className={"flex items-center justify-center h-10 w-10"}>
            <IconButton type={"secondary"} size={"sm"} icon={faPlus} onClick={openAddSLAModal}/>
          </div>

          <div className={"flex items-center justify-center h-10 w-10"}>
            <IconButton type={"danger"} size={"sm"} icon={faTrash} onClick={openDeleteSLAModal}/>
          </div>
        </div>
      }
    </div>


    {props.sla && props.priorities ?
      <div>
        <Accordion header={props.sla.sla_name}
                   data={Object.entries(rulesMap).map(([ruleType, priorityMap]) => {
                     return {
                       itemHeader: slaNameRefactor(ruleType),
                       children: <table>
                         <thead>
                         <tr>
                           <th className={headerStyle}>Prioriteit</th>
                           <th className={headerStyle}>Tijdens kantooruren</th>
                           <th className={headerStyle}>Buiten kantooruren</th>
                         </tr>
                         </thead>
                         <tbody>
                         {Object.entries(priorityMap).map(([priorityId, {
                           inside_office_hours,
                           outside_office_hours
                         }]) => {
                           return <tr key={priorityId} className={""}>
                             <th
                               // @ts-ignore
                               className={headerStyle}>{props.priorities.find(priority => priority.id === priorityId)?.priority}</th>
                             <td
                               className={dataStyle}>{inside_office_hours ? inside_office_hours : 'Nog niet ingesteld'}</td>
                             <td
                               className={dataStyle}>{outside_office_hours ? outside_office_hours : 'Nog niet ingesteld'}</td>
                           </tr>
                         })}
                         </tbody>
                       </table>
                     }
                   })}
        />
      </div>
      :
      <div className={"flex flex-col flex-1 justify-center items-center"}>
        <h1>Deze klant heeft nog geen SLA</h1>
        <Button icon={faPlus} type={"secondary"} size={"sm"} text={"SLA toevoegen"} onClick={openAddSLAModal}/>
      </div>
    }
  </div>
}

const CustomerUsers: FC<{ customer: Customer, users: User[], reload: () => void }> = (props) => {
  const {open: openAddModal} = useModal({title: 'Gebruiker toevoegen', body: <AddCustomerUserModal customer={props.customer}/>, size: "md"
  })
  const filteredUsers = props.users.sort((a, b) => a.name.localeCompare(b.name))
  return <div className={"flex flex-col"}>
    <div className={"flex justify-end p-2"}>
      <Button type={"primary"} size={"sm"} icon={faAdd} text={"Gebruiker toevoegen"} onClick={openAddModal}/>
    </div>
    <SearchPaginateReload data={filteredUsers} placeholder={'Zoek op naam, e-mail of telefoonnummer'} filterProperties={['name', 'email', 'phone',]}
                          onReload={() => props.reload()} paginationEnabled={true} resultsBuilder={(filteredUsers) => {
      return <div className={'grid grid-cols-4 gap-4'}>
        {filteredUsers.map((user, i) => <CustomerUserCard customer={props.customer} user={user} key={i} />)}
      </div>
    }}/>
  </div>
}

const CustomerUserCard: FC<{ customer: Customer, user: User }> = (props) => {
  const modal = useModal({title: 'Gebruiker bewerken', body: <EditCustomerUserModal customer={props.customer} user={props.user}/>, size: "md"})
  return <div className={"bg-white px-4 py-3 rounded border border-slate-200"}>
    <div className={'font-medium text-lg h-6'}>{props.user.first_name} {props.user.middle_name} {props.user.last_name}</div>
    <div className={'text-sm my-2 text-slate-800'}>{props.user.email}</div>
    <div className={'text-sm my-2 text-slate-800'}>{props.user.phone ?? '-'}</div>
    <Button type={'primary'} size={'sm'} text={'Bewerken'} onClick={() => modal.open()}/>
  </div>
}


